import { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import Style from './FJTemplateListSwiper.module.less';
import classname from 'classnames';
import FJSvg from '../../FJSvg/FJSvg';
import arrowRight from '../../../src/assets/img/home/arrowRight.svg';

export default class FJTemplateListSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceBetween: 10,
      isBegin: true,
      isEnd: false,
    };
  }

  render() {
    const {
      slidesPerView = 1,
      clientData,
      drawSwiperSlideItem,
      numberOfRows,
      numberOfColumn,
      style,
      autoHeight = false,
      modules = ['Pagination', 'Navigation'],
    } = this.props;
    let { isBegin, isEnd } = this.state;
    let classLeft = classname(
      Style.preBtn,
      Style.btn,
      isBegin || clientData.length <= 1 ? Style.none : '',
      'swiper-button-left',
    );
    let classRight = classname(
      Style.nextBtn,
      Style.btn,
      isEnd || clientData.length <= 1 ? Style.none : '',
      'swiper-button-right',
    );

    let moduleArr = [];
    let pagination = {};
    let navigation = false;
    if (modules.includes('Pagination')) {
      moduleArr.push(Pagination);
      pagination = {
        clickable: true,
      };
    }
    if (modules.includes('Navigation')) {
      moduleArr.push(Navigation);
      navigation = {
        nextEl: '.swiper-button-right',
        prevEl: '.swiper-button-left',
      };
    }

    return (
      <Swiper
        autoHeight={autoHeight}
        spaceBetween={this.state.spaceBetween}
        slidesPerView={slidesPerView}
        modules={moduleArr}
        pagination={pagination}
        navigation={navigation}
        onSlideChange={data => {
          this.setState({
            isBegin: data.isBeginning,
            isEnd: data.isEnd,
          });
        }}
        className={Style.swiper}
        style={Object.assign({}, { padding: `0 0 ${clientData.length > 1 ? '50px' : '0'} 0` }, style)}
      >
        {clientData.map((item, index) => {
          return (
            <SwiperSlide key={index} className={Style.swiperSlide}>
              <ul className={classname(Style.moveListItemBox, { [Style.listNoWrap]: numberOfRows === 1 })}>
                {drawSwiperSlideItem({
                  list: item,
                  currentPage: index + 1,
                  numberOfRows,
                  numberOfColumn,
                })}
              </ul>
            </SwiperSlide>
          );
        })}
        {modules.includes('Navigation') && (
          <>
            <div className={classLeft}>
              <FJSvg src={arrowRight} className={Style.leftIcon} />
            </div>
            <div className={classRight}>
              <FJSvg src={arrowRight} />
            </div>
          </>
        )}
      </Swiper>
    );
  }
}
