import { Component } from 'react';
import FJUtil from '../../../src/util/FJUtil';

import FJTemplateListSwiper from './FJTemplateListSwiper';

export default class FJTemplateListContainer extends Component {
  constructor(props) {
    super(props);
    (this.containerMaxWidth = 'max-content'),
      (this.state = {
        itemWidth: 0,
        column: 0,
        dataList: [],
        spaceWidth: 0, // 元素不足以成完整一排时，剩余空间大小

        updatedUITimes: 0, // 渲染次数
      });
  }

  timer = null;

  componentDidMount() {
    this.updateUI();
    FJUtil.addEvent(window, 'resize', this.updateUI);
  }

  componentWillUnmount() {
    FJUtil.removeEvent(window, 'resize', this.updateUI);
  }

  updateUI = () => {
    if (this.timer) {
      this.timer = null;
      clearInterval(this.timer);
    }

    this.timer = setTimeout(() => {
      this.setState({
        updatedUITimes: this.state.updatedUITimes + 1,
      });
      let { minMarginRight = 10, maxMarginRight = 30, basicWidth = 402, numberOfRows = 3, data } = this.props;

      let { width } = this.container.getBoundingClientRect();

      // 容器元素最大边距理论上渲染最大列数
      let column1 = Math.floor((width + maxMarginRight) / (basicWidth + maxMarginRight));
      // 容器元素最小边距理论上渲染最大列数
      let column2 = Math.floor((width + minMarginRight) / (basicWidth + minMarginRight));

      const column = Math.max(column1, column2, 1);

      // 单个元素理论在容器中的最大宽度
      let itemWidth = (width + maxMarginRight) / column - maxMarginRight;

      let allCount = Array.isArray(data) ? data.length : 1;

      // 理论上轮播页数
      let pages = Math.ceil(allCount / column / numberOfRows);

      this._dealData(pages, column, numberOfRows);
      this.setState({ itemWidth, column });

      this.containerMaxWidth = column * (basicWidth + maxMarginRight) - maxMarginRight + 2;
    }, 500);
  };

  _dealData = (pages, column, numberOfRows) => {
    let { data } = this.props;
    let curList = [];
    if (Array.isArray(data)) {
      for (let i = 0; i < pages; i++) {
        curList.push(data.slice(i * column * numberOfRows, (i + 1) * column * numberOfRows));
      }
    }

    this.setState({
      dataList: curList,
    });
  };

  drawSwiperSlideItem(slotProps) {
    let {
      drawItem = (style, item, index, currentPage) => {},
      basicWidth = 402,
      minMarginRight = 10,
      marginTop = 10,
    } = this.props;
    let { numberOfColumn } = slotProps;

    // 生成样式
    function computerStyle(index) {
      let styleText = '';
      if (numberOfColumn === 1) {
        styleText =
          index < numberOfColumn ? `0 ${minMarginRight}px` : `${marginTop}px ${minMarginRight}px 0 ${minMarginRight}px`;
      } else {
        //第一排的元素不需要margin-top, 每行最后一个、最后一个元素的不需要margin-right
        const noNeedRightMargin = (index + 1) % numberOfColumn === 0 || index === slotProps.list.length - 1;

        const isOneRow = index < numberOfColumn;

        styleText = noNeedRightMargin
          ? isOneRow
            ? ''
            : `${marginTop}px 0 0 0`
          : isOneRow
          ? `0 ${minMarginRight}px 0 0`
          : `${marginTop}px ${minMarginRight}px 0 0`;
      }
      return styleText;
    }

    // 判断是否是满行，并补齐空位
    const fullRow = slotProps.list.length % numberOfColumn;
    if (fullRow !== 0) {
      slotProps.list = slotProps.list.concat(new Array(numberOfColumn - fullRow).fill({ type: 'emptyBox' }));
    }

    return slotProps.list.map((item, index) => {
      const style = computerStyle(index);

      if (item.type === 'emptyBox') {
        return (
          <li
            key={item.currentPage + String(index)}
            style={{ margin: style, width: `${basicWidth}px`, height: 0, opacity: 0 }}
          />
        );
      }
      return (
        <li key={item.currentPage + String(index)}>
          {drawItem({ margin: style }, item, index, slotProps.currentPage, this.state.updatedUITimes)}
        </li>
      );
    });
  }

  render() {
    const { containerStyle, minMarginRight, modules, autoHeight, swiperStyle = {} } = this.props;

    return (
      <div ref={ele => (this.container = ele)} style={containerStyle}>
        <FJTemplateListSwiper
          drawSwiperSlideItem={slotProps => {
            return this.drawSwiperSlideItem(slotProps);
          }}
          clientData={this.state.dataList}
          numberOfRows={this.props.numberOfRows || 2}
          numberOfColumn={this.state.column}
          style={{ maxWidth: this.state.containerMaxWidth + 'px', ...swiperStyle }}
          minMarginRight={minMarginRight}
          modules={modules}
          autoHeight={autoHeight}
        />
      </div>
    );
  }
}
